.comite-descripcion
{
    display: grid;
    //background: #FFFFFF;
    border-radius: 16px;
    //padding: 24px;
    gap:8px
}
.comite-descripcion-detalle
{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.comite-usuario
{
    display: grid;
    align-items: center;
    background: #FFFFFF;
    border-radius: 16px;
    //padding: 24px;
    //margin-top: 24px;
    margin-bottom: 24px;
    .metrica-usuarios-header
    {
        display: flex;
        align-items: center;
        
    }
    .usuarios-lista
    {
        display: grid;
        justify-content: end;
        gap: 16px;
        //margin-top: 40px;
        .usuarios-item-header
        {
            display: flex;
            align-items: center;
            gap: 10px;
        }
        .usuarios-item
        {
            display: flex;
            align-items: center;
            width: 1010px;
            height: 80px;
            background: #F5F5F5;
            border-radius: 16px;
            gap:16px;
            .usuarios-item-nombre
            {
                width: 260px;
            }
            .usuarios-item-marcar
            {
                width: 178px;
            }
            .usuarios-item-gerencia
            {
                width: 258px;
            }
            .usuarios-item-puesto
            {
                width: 200px;
            }
        }
    }
}

.p-eva-panel-acordeon.comite
{
    .p-panel-header
    {
        padding-bottom: 0px !important;
    }
}
@media (max-width: 991px) 
{
    .usuarios-item-puesto,
    .usuarios-item-gerencia,
    .usuarios-item-marcar,
    .titulo.gerencia,
    .titulo.area,
    .titulo.puesto
    {
        display: none;
    }
    .usuarios-item
    {
        width: fit-content !important;
    }
}