*{
    box-shadow: 1px #F47647  !important;
    //font-family: "Poppins";
    font-family: poppins, sans-serif;
    }
// .layout-main-container .layout-main .p-eva-dashboard-inicio
// {
//     height: 81vh !important;
// }
label
{
  font-family: poppins, sans-serif;
  // font-style: normal;
  // font-weight: 500 !important;
  // font-size: 14px !important;
  // line-height: 21px !important;
  color: #000000;
}

.p-eva-text
{
    color:#000000;
}
input{
    caret-color: #F47647;
}
::placeholder {
    color: #000000;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
.peva-input-icon
{
  .icon-search::placeholder
  {
    color: #F47647;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
  }
  
}


.p-checkbox-box
  {
      border: 2px solid #F47647 !important;
  }

  .p-checkbox .p-checkbox-box.p-highlight
  {
      border-color: #F47647 !important;
      background: #F47647 !important;
  }
  .eva-dashboard-header
  {
      margin-top: 40px;
      width: 100%;
  }
  .eva-header
  {
      margin-top: 0px;
      width: 100%;
      font-weight: bold;
      font-size: 24px;
      color: #000;
      text-align: center; 
  }
  .p-eva-tituloCard
  {
    padding: 16px !important;
    padding-bottom: 0px !important;
  }
  button{
    border-color: transparent !important;
  }
  .header-agrupado
  {
      margin-top: 32px !important;
      margin-left: 0 !important;
      font-style: normal;
      display: flex;
      width: 100%;
      justify-content: space-between;

  }
  .header-general
  {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #000000;
  }
  .titulo
  {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }
.header-right
{

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    padding-top: 15px;
    /* identical to box height */

    text-align: right;

}
.header-subtitle
{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
}

.header-right a
{
    color: #F47647 !important;
    margin-right: 20px;
}

.p-eva-error:hover
{
    border: 1px solid #DC0922 !important;
}
.p-eva-error
{
    border: 1px solid #DC0922 !important;
    font-size: 12px;
}
.p-eva-success:hover
{
    border: 1px solid #00B560 !important;
}
.p-eva-success
{
    border: 1px solid #00B560  !important;
}
.white
{
    background: #FFFFFF;
}
.grey
{
    background: #F5F5F5;
}
.text-orange{

  color:#F47647 ;
}


.p-card
{
    border-radius: 16px !important;
}

.p-eva-icon-atras
{
    cursor: pointer;
}
.p-card-content
{
  padding-bottom: 0px!important
}
.p-eva-container {
    justify-content: space-between;
    flex-direction: column;
    display: flex;
  }
  .p-eva-container .p-eva-container-boton {
    margin-top: 32px;
    justify-content: flex-end;
    display: flex;
  }
  .p-eva-labelForm
  {
    font-family: poppins, sans-serif;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #000000;
  }
  .p-eva-labelForm-grey
  {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #B5B5B5;
  }
  .p-eva-labelForm-orange
  {
    font-style: normal !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #F47647;
  }
  .p-eva-linkText
  {
    display: flex;
    align-items: center;
    font-style: normal !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    padding-top: 10px;
    color: #F47647;
    

  }
  .p-eva-link-multiple
  {
    font-style: italic !important;
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    padding-top: 10px;
    color: #F47647;
  }

  ::-webkit-scrollbar {
    width: 8px;

  }
  ::-webkit-scrollbar-track
  {
    border-radius: 8px !important;
    background: #F5F5F5;
  }
  ::-webkit-scrollbar-thumb {
    background: #463347 !important;
    border-radius: 8px !important;
  }

  .p-listbox
  {
    border: none;
  }

  .p-eva-topbar-usuario
  {

    display: flex;
    align-items: center;
    order: 3 ;
    list-style: none;
    margin: 0 0 0 auto;
  }

  .bage-notificaciones
  {
    background:#F47647;
    border: 1px solid #FFFFFF;
    font-weight: 700;
    font-size: 12px !important;
    line-height: 18px;
    text-align: center;
    border-radius: 50% !important;
    padding: 1px;
  }

  .p-datepicker table td.p-datepicker-today > span
  {
    background: #F47647 50% !important;
    opacity: 0.6;
    color: #FFFFFF;
    border-color: transparent;
  }

  .p-datepicker table td > span.p-highlight {
    color: #F47647 !important;
    background: rgba(244, 118, 71, 0.2) !important;
    span
    {
      border-bottom: 1px solid  #F47647 !important;

    }
  }
  .p-datepicker table td > span
  {
    border-radius: 8px !important;
  }
  .p-datepicker-month.p-link,
  .p-datepicker-year.p-link
  {
    color: #000000 !important;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
  }

  .eva-labelFile
{
    display: flex;
    // position: relative;

    // border: 1px solid black;
    padding: 10.5px;
    width: 100%;
    background: #F5F5F5;
    border-radius: 16px;
}
.eva-labelFile-icon
{
    position: absolute;
    left:89%;
    padding-top: 4px;
   
}
.p-eva-footer-form
{
    display: flex;
    justify-content: right;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider
{
  background: #00B560;
}

.eva-labelFileLider
{
    display: flex;
    // position: relative;

    // border 1px solid black;
    padding: 8.8px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 16px;
}
.eva-labelFileLider-icon
{
    position: absolute;
    left:89%;
    padding-top: 4px;
}

.repositorio-item
{
    position: relative;
    display: grid;
    justify-content: center;
    padding: 16px;
    width: 344px;
    //height: 232px;
    height: 268px;
    border-radius: 16px;
    background: #FFFFFF;

}
.repositorio-item-delete
{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(300px,10px);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background: #F47647;
  cursor: pointer;
}
// .repositorio-item-header
// {
//     display: flex;
//     justify-content: center;
//     width: 344px;
//     height: 136px;
// }

.repositorio-item-header
{
    display: grid;
    align-items: center;
    justify-content: center;
    div
    {
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        
        color: #000000;
        padding-top: 15px;
    }
}
.repositorio-item-header-nombre
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 312px;
    height: 136px;
    background: #F5F5F5;
    border-radius: 8px;

    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;

    text-align: center;

    color: #000000;
}

.repositorio-item-footer
{
    display: flex;
    align-items: center;
    width: 344px;
    height: 64px;
    justify-content: center;
    padding-top: 16px;
}
.p-eva-panel-acordeon
{
  background: #FFFFFF;  
  border-radius: 16px !important;
  margin-top: 24px;
  .p-panel-header
  {
    // border-top-right-radius: 16px !important;
    // border-top-left-radius: 16px !important;
    border-radius: 16px !important;
    background: #FFFFFF;
    border: 0;
    padding: 1.5rem 1.5rem !important;
    align-items: start;
  }
  .p-panel-content
  {
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
    border: 0;
    padding: 1.5rem 1.5rem !important
  }
}

.body-repositorio
{
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    grid-auto-rows: minmax(100px, auto);
}
.reconocimiento-item-img
{
    display: flex;
    align-items: center;
    justify-content: center;
    img
    {
        border-radius: 16px;
    }
}
.reconocimiento-item-nombre
{
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-style: normal;
    font-weight: 700;
}
.p-eva-PreviewMedia-contenido .slick-slider .slick-dots {
  display: none !important;
}
@media (max-width: 991px) 
{
  .header-right
  {
    text-align:  center !important;
  }
}
.capacitacion-anio > input
{
  border-radius: 16px !important;
  border:none
}