.detalleCapaOpcional-adicional
{
    display: flex;
}
.adicional-item
{
    // display: flex;
    border-radius: 16px;
    background: #ffffff;
    margin-right: 24px;
    padding: 24px;
    height: 175px;
    width: 50%;
    
}
.capaOpcional-desccripción
{
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-top: 16px;
    margin-bottom: 24px;
    color: black;
}
// @media (max-width: 991px) {
    
//     .eva-capaOpcional
//     {
//         width: 100% !important;
//     }
    
// }