@import "rsuite/dist/rsuite.css";
.capaObligatoria-item
{
    display: flex;
    background: #ffffff;
    flex-direction: row;
    align-items: center;
    padding: 12px 8px;
    gap: 8px;
    width: 100%;
    height: 84px;
    border-radius: 12px;
}

.capaObligatoria-item-detalle
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 73%;
}
.capaObligatoria-item-detalleUsuario
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 92%;
}

.capaObligatoria-item-detalle-titulo
{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    height: 32px;
}
.detalle-titulo-nombre
{
   
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.011em;
    text-align: left;
    color: #000000;
}
.detalle-titulo-categoria
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;

    // width: 56px;
    height: 32px;

    background: rgba(244, 118, 71, 0.1);
    border-radius: 16px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #F47647;
}
.capaObligatoria-item-detalle-datos
{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    height: 20px;
}
.detalle-datos-item
{
    height: 20px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.011em;

    /* Basic/Negro */

    color: #000000;
}
.detalle-datos-barra
{
    width: 0px;
    height: 20px;

    /* Basic/Gris oscuro */

    border: 1px solid #B5B5B5;
}
.capaObligatoria-item-opciones
{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;

    width: 232px;
    height: 48px;

}
.item-opciones-descarga
{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;

    width: 194px;
    height: 48px;
}
.eva-dashboard-preguntas
{
    padding: 24px;
    background: #FFFFFF;
    border-radius: 16px;
    .rs-steps-horizontal .rs-steps-item:not(:first-child) .rs-steps-item-icon-wrapper {
        /* left: 10px; */
        left: 0;
    }
    .rs-steps-horizontal .rs-steps-item:not(:first-child) {
        padding-left: 30px
    }
    .rs-steps-item {
        padding-left: 30px;
    }
    
    .rs-steps-item-status-process .rs-steps-item-icon-wrapper {
        color: #00B560;
        background-color: #E6F8EF !important;
        border:solid 2px #00B560 !important;
        
    }
    .rs-steps-item-status-process .rs-steps-item-content {
        color: #00B560 !important; 
    }
    
    .rs-steps-item-status-process .rs-steps-item-tail, .rs-steps-item-status-process .rs-steps-item-title::after {
        // border-color: #00B560 !important;
        width: 300px;
        border-image: linear-gradient(to right, #00B560 0%, #00B560 40%, #B5B5B5 30%,#B5B5B5 100%) 3;
    
    }
    .rs-steps-item-status-finish .rs-steps-item-tail, .rs-steps-item-status-finish .rs-steps-item-title::after {
        border-color: #00B560;
    }
    
    .rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
        background-color:#00B560;
        border-color: #00B560;
        color: #ffffff;
        
    }
    .rs-steps-item-icon-wrapper
    {
        border: 2px solid;
    }
    
    .rs-steps-horizontal .rs-steps-item-title::after {
        
        border-top-width: 2px;
        border-top-style: solid;
    }
    
    .rs-steps-item-status-wait .rs-steps-item-icon-wrapper {
        background: #F5F5F5;
        border-color: #F5F5F5;
        color: #B5B5B5
        
    }
    
    .rs-steps-item-tail, .rs-steps-item-title::after {
        border-color: #B5B5B5;
    }
}


// .rs-steps-item-status-process .rs-steps-item-tail, .rs-steps-item-status-process .rs-steps-item-title::before {
//     border-color: #B5B5B5 !important;
//     width: 250px;
// }

.preguntas-steps
{
    padding: 16px 60px;
}
.preguntas-detalle-alternativas
{
    .p-listbox .p-listbox-list .p-listbox-item {
        color: #000000;
        background:none;
        border: 1px solid #B5B5B5;
        border-radius: 8px;
    }
    .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
        color: #000000;
        background:none;
        border: 2px solid #F47647;
        border-radius: 8px;
    }
    .p-listbox-item
    {
        margin-top: 16px !important;
    }
}

.dialogRespuestas-mensaje
{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px
}

.respuestaCorrectas-exitoso
{
    color:#00B560;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
}
.p-eva-dialogRespuestas
{
    
    width: 540px;
    height: 240px;
    //padding: 24px;
}
.confirmaRespuestas
{
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    
   
}
.respuestaCorrectas-error
{
    color:#DC0922 ;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
}
.detalle-datos-item-exito
{
    color:#00B560;
}
.detalle-datos-item-error
{
    color:#DC0922 ;
}
.p-eva-dialogEditar
{
    .p-dialog-header
    {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .p-dialog-content
    {
        padding-bottom: 16px;
    }
}
.p-eva-dialogAlerta
{
    .p-dialog-header
    {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .p-dialog-content
    {
        padding-bottom: 16px;
    }
    width: 540px;
}
.capaObligatoria-container-lista
{
    display: grid;
    gap: 16px;
}
@media (max-width: 991px) 
{
    
    .eva-capaObligatoria
    {
        width: 100% !important;
    }
    .detalle-titulo-nombre
    {
        font-size: 14px !important;
    }
    .capaObligatoria-item
    {
        //display: grid;
        height: fit-content;
        width: 95vw;
    }
    .item-opciones-descarga
    {
        width: 100% !important;
    }
}