.encuesta-timeline
{
    display: grid;
    width: 1082px;
    height: 258px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 24px;
}
.encuesta-timeline-detalle
{
    // .rs-steps-horizontal .rs-steps-item:not(:first-child) .rs-steps-item-icon-wrapper {
    //     /* left: 10px; */
    //     left: 0;
    // }
    .rs-steps-horizontal .rs-steps-item:not(:first-child) {
        padding-left: 0
    }
    .rs-steps-item {
        padding-left: 0
    }
    .rs-steps-item-status-process .rs-steps-item-icon-wrapper {
        color: #00B560;
        background-color: #E6F8EF !important;
        border:solid 2px #00B560 !important;
        
    }
    .rs-steps-item-status-process .rs-steps-item-content {
        color: #00B560 !important; 
    }
    
    .rs-steps-item-status-process .rs-steps-item-tail, .rs-steps-item-status-process .rs-steps-item-title::after {
        // border-color: #00B560 !important;
        width: 300px;
        border-image: linear-gradient(to right, #00B560 0%, #00B560 40%, #B5B5B5 30%,#B5B5B5 100%) 3;
    
    }
    .rs-steps-item-status-finish .rs-steps-item-tail, .rs-steps-item-status-finish .rs-steps-item-title::after {
        border-color: #00B560;
    }
    
    .rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
        background-color:#00B560;
        border-color: #00B560;
        color: #ffffff;
        
    }
    .rs-steps-item-icon-wrapper
    {
        display: none;
    }
    // .rs-steps-item-content
    // {
    //     display: flex;
    //     align-items: center;
    // }
    .rs-steps-item-title
    {
        display: flex;
        align-items: center;
        border-radius: 100px;
        border: 2px solid;
        padding-left: 8px;
        padding-right: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        height: 32px;
        width: fit-content;
    }

    .rs-steps-horizontal .rs-steps-item-title::after {
        
        border-top-width: 2px;
        border-top-style: solid;
    }
    
    .rs-steps-item-status-wait .rs-steps-item-icon-wrapper {
        background: #F5F5F5;
        border-color: #F5F5F5;
        color: #B5B5B5
        
    }
    
    .rs-steps-item-tail, .rs-steps-item-title::after {
        border-color: #B5B5B5;
    }

    .rs-steps {
        min-height: 60px;
    }
    .rs-steps-horizontal .rs-steps-item-content {
        height: 60px;
    }
    .rs-steps-item-status-finish .rs-steps-item-content .rs-steps-item-title{
        background-color:#00B560;
        border-color: #00B560;
        color: #ffffff;
    }
    .rs-steps-item-status-process .rs-steps-item-content .rs-steps-item-title{
        background:#E6F8EF;
        
    }
    .rs-steps-item-status-wait .rs-steps-item-content .rs-steps-item-title{
        background: #F5F5F5;
        border-color: #F5F5F5;
        color: #B5B5B5;
    }

    .rs-steps-item-status-process .rs-steps-item-content .rs-steps-item-description
    {
        .link-timeline{
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */
            
            // display: flex;
            // align-items: center;
            // text-align: center;
            letter-spacing: -0.011em;
            text-decoration-line: underline;
            
            /* Branding/Naranja */
            
            color: #F47647;

        }
        .actual-item-titulo
        {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: start;
            letter-spacing: -0.011em;

            /* Basic/Negro */

            color: #000000;

        }
        .actual-item-estado
        {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */

            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: -0.011em;

            /* Basic/Negro */

            color: #000000;
        }
    }
    // .mes-detalle-actual-item
    // {
    //     display: flex;
    //     align-items: center;
    //     justify-content: start;

    // }
    .rs-steps-item-status-wait .rs-steps-item-content .rs-steps-item-description,
    .rs-steps-item-status-finish .rs-steps-item-content .rs-steps-item-description{
        .actual-item-titulo,
        .link-timeline{
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            display: flex;
            align-items: center;
            justify-content: start;
            text-align: start;
            letter-spacing: -0.011em;
            text-decoration: none;
            pointer-events: none;

            color: #B5B5B5;

        }
        .actual-item-estado
        {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */

            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: -0.011em;

            /* Basic/Gris oscuro */

            color: #B5B5B5;

        }
    }
}
@media (max-width: 991px) 
{
   
    .eva-dashboard-laboral
    {
        width: 100% !important;
    }
}