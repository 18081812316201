.p-eva-mensajeColaborador 
{
    width: 100%;
    .p-card-header
    {
        padding-top:  16px !important;
        padding-left:  16px !important;
    }
    .p-card-content
    {
        padding-top: 0px;
    }
}
.p-eva-mensajeColaborador-footer
{
    display: flex;
    justify-content: end;
}