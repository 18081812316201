.p-eva-PreviewMedia
{
    border-radius: 16px !important;
    display: flex;
   // background-color: aqua;
    // align-items: center;
    // position: relative;
    padding: 0;
    .p-dialog-header
    {
        background: transparent !important;
        // position: absolute;
        
        .p-dialog-header-icons
        {
            background-color: #FFFFFF;
            border-radius: 50%;
            
        }
    }
    .p-dialog-content
    {
        
        align-items: center;
        width: 100%;
        background: transparent !important;
        overflow: hidden;
        justify-content: center;
        padding: 0;
        
    }
    .p-dialog-header
    {padding-bottom: 0;}

    .p-eva-PreviewMedia-contenido
    {
        max-width: 85vw;
        margin-left: -40px;
        //background-color: rgb(188, 16, 134);
      //  height: 87vh;
    }
}
@media (max-width: 992px) {
    .p-eva-PreviewMedia
    {
        max-height: 67vh !important;
        max-width: 95vw !important;
        .p-eva-PreviewMedia-contenido
        {
           //max-height: 40vh !important;
            //max-height: 40% !important;
            height: auto !important;
            width: 100% !important;
        }
    }
    
}