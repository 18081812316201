.actividades-calendario-body
{
    display: grid;
    background: #FFFFFF;
    border-radius: 16px; 
    width: 1082px;
    height: 795px;   
    padding: 24px;
}
.calendario-body-descripcion
{
    display: flex;
    justify-content: start;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* Basic/Negro */
    color: #000000;
    margin-bottom: 16px;
}
.calendario-body-meses
{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 18px;
    grid-auto-rows: minmax(100px, auto);
}
.mes-item
{
    width: 245px;
    height: 226px;
    background: #FFFFFF;
    border: 1px solid #B5B5B5;
    border-radius: 8px;
    padding: 16px;
}
.mes-item.pasado
{
    //pointer-events: none;
    background: #FFFFFF;
    
    border: 1px solid #B5B5B5;
    .mes-item-nombre{
        color: #B5B5B5;
    }
    .actividades-item{
       // color: #B5B5B5;
        color: #000000;

    }
    .actividades-item-left{
        background: #B5B5B5;
    }
}
.mes-item.actual{

    background: #FFFFFF;
    
    border: 1px solid #000000 !important;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
    .mes-item-nombre{
        color: #F47647;
    }
    // .actividades-item-left{
    //     background: #F47647;
    // }
    
}
.mes-item-nombre
{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    color: #000000;
}

.mes-item-body-vacio
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #B5B5B5;
    display: flex;
    align-items: center;
    margin-top: 58px;
}


.mes-item-actividades
{
    max-height: 160px;
    margin-top: 24px;
    display: grid;
    align-items: center;
    gap: 8px;
    overflow-y: auto;
    overflow-x: hidden;
}

.actividades-item-container
{
    display: flex;
    align-items: center;
    //height: 32px;
    max-height: 50px;
   cursor: pointer;
}
.actividades-item
{
    //position: relative;
    display: flex;
    width: 211px;
    //height: 32px;
    max-height: 50px;
    padding: 6px 8px 5px 10px;
    background: #F5F5F5;
    border-radius: 0px 8px 8px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #000000;
    
    
}
.actividades-item-left{
    //position: absolute;
    width: 4px;
    height: 32px;
    //min-height: 100%;
    max-height: 50px;
    transform: translateX(3px);
    //transform: translate(-12px,-6px);
    background: #B5B5B5;
    border-radius: 8px;
}
.actividad-panel{
    //width: 209px;
    max-width: 240px;
    // height: 170px;
    max-height: 290px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0px 8px 8px 8px;
    transform: translate(205px,-12px);
    .p-overlaypanel-content
    {
        padding: 16px;
    }
}
.field-detalle 
{
    margin-bottom: 8px;
}
// .actividad-panel-detalle
// {
//     //display: grid;
//     width: 182px !important;
//     //padding: 16px;
// }
.panel-detalle-header
{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}
.panel-detalle-info
{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
}

.link-actividad
{
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #F47647 !important;

}
.actividad-switch{

    .p-inputswitch {
        width: 92px;
        height: 32px;  
       
    }
    
    .p-inputswitch .p-inputswitch-slider {
        /* background: #ced4da; */
        /* transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s; */
        border-radius: 8px;
        background: #ffffff
        
    }
    
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        background: #463347;
    }
    
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::after {
        content: "AM";
        display: flex;
        padding: 6PX;
        padding-left: 9px;
        align-items: center;
        justify-content: start;
            
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.011em;
        color: #B5B5B5;
    }
    
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        transform: translateX(3.15rem);
        content: "PM";
        display: flex;
        padding: 4PX;
        justify-content: center;
        color: #ffffff;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
    
    .p-inputswitch .p-inputswitch-slider:before {
        background: #463347;
        width: 40px;
        height: 28px;
        left:4px;
        margin-top: -1.02rem;
        border-radius: 8px;
        transition-duration: 0.2s;
        content: "AM";
        display: flex;
        padding: 4px;
        justify-content: center;
        color: #ffffff;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #ffffff;
    }
    
    .p-inputswitch .p-inputswitch-slider::after
    {
        content: "PM";
        display: flex;
        padding: 6PX;
        padding-right: 9px;
        align-items: center;
        justify-content: end;
            
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.011em;
        color: #B5B5B5;
    }
    
    .p-overlaypanel:after, .p-overlaypanel:before {
        bottom: 0;
        
    }
    
}


@media (max-width: 991px) 
{
    .eva-dashboard-editarEvento
    .eva-dashboard-editarCalendario,
    .eva-dashboard-crearActividades,
    .eva-detalleEventos,
    .eva-actividades
    {
        width: 100% !important;
    }
    .actividades-calendario-body
    {
        width: 100% !important;
        height: fit-content;
        .calendario-body-descripcion
        {
            width: fit-content !important;
        }
        .calendario-body-meses
        {
            grid-template-columns: repeat(1, 99%);
            grid-auto-rows: minmax(99%, fit-content);
            .mes-item{
                width: 100% !important;
                .mes-item-actividades
                {
                    //width: 100% !important;
                    .actividades-item-container
                    {
                        width: 99% !important;
                        //height: auto !important;
                        //margin: auto !important;
                    }
                }
            }
        }
    }
    .actividad-panel
    {
        left: -120px !important;
        max-width: 75vw !important;
        
    }
    .body-repositorio {
        
        grid-template-columns: repeat(1, 1fr);
        justify-content: center !important;
        grid-auto-rows: minmax(100px, auto);
        .repositorio-item
        {
            width: 100% !important;
            .repositorio-item-delete
            {
                transform: translate(350px, 10px);
            }
        }
    }
}