.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    //padding: 72px 2rem 2rem 4rem;
    padding: 72px 2rem 2rem 12px;
    transition: margin-left $transitionDuration;
    margin-top: 0;
    overflow-x: auto;
}

.layout-main {
    flex: 1 1 auto;
}
@media (max-width: 991px) {

    .layout-main-container
    {
        padding: 0!important;
        //padding-top: 72px !important;
        padding-top: 12px !important;
        
    }
}