.p-eva-dashboard-link
{
    .p-card-body
    {
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
        .p-card-content
        {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: black;
            i{
                color: #F47647;
                font-weight: 700;
                cursor: pointer;
            }
        }
    }
}
.p-eva-DetalleTema-cardContactIcon
{
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin: 24px;
    span 
    {
        padding-top: 0px;
        padding-bottom: 2px;
        padding-left: 6px;
    }
}
.p-eva-dashboard-link
{
    background: #ffffff;
    padding: 15px;
    border-radius: 16px;
}
.header-subtema
{
    font-style: normal;
    font-weight: 700;
    font-size: 18px !important;
    line-height: 27px !important;
}

.p-eva-detalleTema-contactCard
{
    display: flex;
    
}
.p-eva-detalleTema-contactCard.p-card-body
{
    .p-card-content
    {
        display: flex;
    }
    
}
.temadetalle-item-contactCard
{
    margin-right: 12px;
    margin-left: 12px;
    margin-bottom: 12px;
}
@media (max-width: 991px) {
    .p-eva-dashboard-DetalleTema,
    .eva-DetalleTema
    {
        width: 100% !important;
    }
}