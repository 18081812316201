p.error {
    margin: 0 0 10px 10px;
    text-align: left;
    font-size: 10px;
    color: red;
  }

  .peva-dialog-password .p-dialog-content
  {
    margin: 24px;
    width: 443px;
    height: fit-content;
    border-radius: 16px !important;
    padding-bottom: 24px;
  }