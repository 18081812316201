.cardPublicacion-comentarios-item
{
    display: inline-flex;
    align-items: flex-start;
    
}
.comentario-detalle
{
    width: 95% !important;
    .p-card
    {
        box-shadow: none
    }
}

.p-eva-comentario-img
{
    display: flex;
    justify-content:start;
    width: 100%;
    height: 100%;
    margin-top: 8px;
    img
    {
        border-radius: 16px;
        height: 140px !important;
    }
}
.cardComentario
{
    position: relative;
    .cardComentario-trash
    {
        position: absolute;
        transform: translateX(525px);
        cursor: pointer;
    }
}