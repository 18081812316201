.p-eva-cardCorreo
{
    background: #ffffff;
    height: 227px;
    width: 100%;
    border-radius: 16px;
    margin-bottom: 16px;
    padding: 16px;
    .p-eva-cardCorre-body
    {
        display: grid;
        justify-content: center;
        align-items: center;
        background: #F5F5F5;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        padding: 16px;
        .cardCorre-body-texto,
        .cardCorre-body-boton,
        .cardCorre-body-img{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .cardCorre-body-texto{
            color: #000000;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
        }
    }
}
