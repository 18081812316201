.eva-card-contact
{
    position: relative;
    border-radius: 16px;
    //width: 344px;
     height: 308px;
    //  width: 100% !important;
     //margin-bottom: 40px;
}
.eva-card-contact-profile
{
    position: absolute;
    transform: translate(1px, -75px);
}
.eva-card-contact-image
{
    border-radius: 50%;
    height: 112px;
    width: 112px;
    
}
.eva-card-contact-profile-name
{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    padding-left: 120px;
    //margin-bottom: 17px;
    transform: translate(1px, -15px);
    height: 41px;
    text-align: left !important; 
}

.eva-card-contact-profile-puesto 
{
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    span 
    {
        padding-top: 0px;
        padding-bottom: 2px;
        padding-left: 6px;
        text-align: left !important;
    }
}


.eva-card-contact-profile-email , .eva-card-contact-profile-area ,.eva-card-contact-profile-gerencia 
{
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin-top: 5px;
    span 
    {
        padding-top: 0px;
        padding-bottom: 2px;
        padding-left: 6px;
    }
}
@media (max-width: 991px) 
{
    .eva-card-contact
    {
        width: 100% !important;
    }
}

.sombreado{
    /* box-shadow: 15px 15px 20px orange; */
    border: 5px solid #F47629;
}

.contenido-div-center{
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}