.p-eva-previeMedia-container
{
    background: transparent;
    height: 100%;
    margin: 20px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    //flex-direction: column;
    img
    {
        width: 60%;
    }
}
.close-icon-img
{
    position: absolute;
    cursor: pointer;
    transform: translate(-5px,-20px);
}