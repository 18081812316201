.p-eva-timeLine
{
    margin-top: 24px;
    background: white;
    padding: 24px;
    padding-top: 34px;
    border-radius: 16px;
}
.p-eva-timeLineMarker
{
     display: flex;
    //  align-items: center;
    background: #00B560;
    padding: 6px;
    border-radius: 100px;
   
}
.p-timeline-event-connectorEva
{
    height: 2px;
    background-color: #F5F5F5;
     width: 100%;
        
}
.p-timeline-event-connectorEva.Finalizado
{
    background-color: #00B560 !important;   
    width: 100% !important;
}

.p-timeline-event-connectorEva.Actual
{
    height: 2px !important;
    background-color: #00B560 !important;
    width: 50%;
}
.p-timeline-event-connector
{
    // display: none;
    width: 0% !important
}   

.p-eva-timeLineMarker.Actual
{
    background: #E6F8EF !important;
    border: 2px solid #00B560;
    span
    {
        color: #00B560 !important;
    }
}
.p-eva-timeLineMarker.Pendiente
{
   background: #F5F5F5;
   span
   {
    color: #B5B5B5 !important; 
   }
}


.p-eva-timeLineContenido
{
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 16px;
    // margin-left: -152px;
}
.timeLineContenido-evaluacion
{
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: #B5B5B5;
    text-align: center;
}
.p-eva-timeLine
{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #B5B5B5;
    text-align: center;
}

.p-eva-timeLine .p-timeline-event-content,
.p-eva-timeLine .p-timeline-event-opposite {
    line-height: 1;
}
// .p-timeline-event
// {
//     display: flex;
//     align-items: center;
   
// }
.p-eva-timeLine .p-timeline-event-content
{
    min-height: 180px;
    min-width: 182px;
    // max-width: 172px;
    //min-width: fit-content;
}
// .p-eva-timeLineContenido
// {
//     min-width: 172px;

// }
// .p-eva-timeLineContenido.Enero
// {
//     width: 172px !important;
// }
// .timeLineContenido-estado.Actual,
.timeLineContenido-evaluacion.Actual
{
    color:black
}

// .p-timeline-horizontal .p-timeline-event
// {
//     flex: 0 1;
// }

.timeline-seccion-link
{
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;

    width: 1082px;
    height: 96px;


    background: #FFFFFF;
    border-radius: 16px;
    .seccion-link-texto
    {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;

        /* Basic/Negro */

        color: #000000;
    }
}

.card-historia-item
{
   
    min-width: 349px;
}