.capaMia-fechaLimite
{
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid #463347;
    border-radius: 16px;
    background: rgba(70, 51, 71, 0.1);
    padding-left: 16px;
    height: 64px;
}
.fechaLimite-right 
{
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;

}

.fechaLimite-left
{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 16px;

}
.capaMia-link
{
    display: flex;
    justify-content: right;
    margin-top: 24px;
    
}

@media (max-width: 991px) {

    .eva-dashboard-editarCapaMia,
    .eva-capaMia
    {
        width: 100% !important;
    }
   
}