.p-eva-multiselect-tag
{
    margin-top: 22px;
    

}
.p-eva-multiselect .css-1s2u09g-control
{
    border-radius: 16px !important;
    width: 100% !important;
    border:1px solid #F47647 !important;
    color: #F47647 !important;
    box-shadow: none !important;
}

.p-eva-multiselect .css-1pahdxg-control
{
    border-radius: 16px !important;
    width: 100% !important;
    border:1px solid #F47647 !important;
    color: #F47647 !important;
    box-shadow: none !important;
}

.css-14el2xx-placeholder
{
    color: #F47647 !important;
}