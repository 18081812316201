.p-eva-toastEva
{
    opacity: 1;
}
.p-eva-toastEva .p-toast-message-success,
.p-eva-toastEva .p-toast-message-error
{
    font-weight: 700 !important;
   .p-toast-message-content
   {
        display: flex;
        align-items: center;
        span
        {
            color: #ffffff !important;
        }
        .p-toast-message-text
        {
            .p-toast-detail
            {
                margin: 0;
            }
        }
   }
    border: none !important; 
    border-width:0 !important;
    .p-toast-message-text
    {
        .p-toast-summary
        {
            display: none;
        }
    }
}
.p-eva-toastEva .p-toast-message-error
{
    background :#DC0922 !important;
    color: #ffffff !important;
    
    
}

.p-eva-toastEva .p-toast-message-success
{
    background :#16A34A !important;
    color: #ffffff !important;
    
    
}