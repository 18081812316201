.p-eva-crearPublicacion
{
    margin-bottom: 16px;
    .p-card-body
    {
        padding-top: 0px;     
        width: 100% !important;
        .p-card-content
        {
            width: 100% !important;
        }
    }
}
.p-eva-crearPublicacion-basico
{
    display: inline-flex;
    input
    {
        width: 93%
    }
   
}
.p-eva-crearPublicaion-Publicar
{
    width: 100%;
    display: inline-flex;
    justify-content: end !important;
}
.eva-button-emojis
{
    position: relative;
}
.eva-EmojisIcons
{
    position: absolute;
    z-index: 1;
    transform: translateX(250px);
}