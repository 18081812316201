.eva-dashboard-directorio-body
{
    margin-top: 32px;
}

.titulo-organigrama{
    text-align: center;
    color: #F47629;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 0px;
    height: 40px;
}
.contenerdor-reporte-organigrama{
    background: #eeeeee;
    padding-top: 5%;
}
.card-detalle-usuario{
    text-align: -webkit-center;
}