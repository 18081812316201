.eva-dashboard-header
{
    width: 600px;
    left: 318px;
    top: 112px;

    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
}

.eva-dashboard
{
    width: 100%;
}

.eva-dashboard-usuarios .containerTab
{
    width: 100%;
    height: 100%;
    margin-top: 32px;
}

.peva-input .p-dropdown-label 
{
    padding-top: 0px;
}
.peva-footer .p-button
{
    width:100%;
    height: 48px;
    
}
.peva-button-usuarios
{
    background: #F47647;
    border: none;
    justify-content: flex-end;
    border-radius: 16px;
}

.peva-button-usuarios:hover
{
    background: #463347  !important;
}

.peva-footer
{
    align-items: flex-end;
}

.eva-dashboard-usuarios .peva-icon-rigth
{
    display: inline-block;
    left: 96% !important;
}
.icon-search
{
    border: 2px solid!important;
    color: #F47647 !important;
    
}
.icon-search::after
{
    color: #F47647 !important;
}
.peva-card-user
{
    background: #ffffff;
    height: 193px;
    width: 257px !important;
    margin: 8px;
}

.peva-card-user-body
{
    height: 120px;
    
}
.peva-card-user-button
{
    border-radius: 16px;
    background-color: #F47647;
    height: 48px;
    width: 109px;
}

.peva-card-content
{
  position: relative;
  margin-top: 64px;
  height: 128px;
}

.peva-profile
{
    position: absolute;
    top: -45px;
    left: 16px;
    width: 100%;
    z-index: 2;
    text-align: center;
}
.peva-profile-edit
{
    position: absolute;
    display: flex;
    align-items: center;
    transform: translate(269px,-8px);
     z-index: 2;
    // width: 100%;
    // right: 10px;
    // top:10px;
    // text-align: end;
}
.peva-profile-name
{
    position: absolute;
    z-index: 2;
    width: 45%;
    right: 70px;
    text-align: center;

    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
}
.peva-profile-email
{
    position: absolute;
    z-index: 2;
    width: 100%;
    text-align: start;
    top: 90px;
    display: inline-flex;
    align-items: center;
    
    span 
    {
        padding-top: 0px;
        padding-bottom: 2px;
        padding-left: 6px;
    }
}
.peva-profile-image
{
    display: block;
    border-radius: 120px;
    border: 1px solid #fff;
    width: 112px;
    height: 112px;
}
.peva-profile-icon
{
    display: block;
}
// .peva-profile-email span
// {
//     top:-30px;
// }
.peva-gerencia
{
    margin-top: 16px;
    font-weight: 700;
    font-size: 18px;
}
.peva-colaboradores
{
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
}