.p-eva-cardGrupo
{
    position: relative;
    padding: 0;
    height: 296px;
    width: 100%;
    max-width: 344px;
}
.p-eva-container-img
{
    width: 100% !important;
    height: 152px;
    
}
.p-eva-img
{
    width: 100% !important;
    max-width: 344px;
    height: 152px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.p-eva-container-nameGroup
{
    padding: 16px;
    padding-bottom: 0px;

    font-style: normal;
    font-weight: 900;
    font-size: 17px;
    line-height: 27px;
}
.p-eva-container-number
{
    padding: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #B5B5B5;
}
.p-eva-primary
{
    background: #463347;
    border-radius: 16px;
}

.buttonGroup
{
    margin: 16px;
}
.p-eva-grupos-container-icon
{
    position: absolute;
    z-index: 2;
    width: 100%;
    // right: 10px;
    // right: -270px;
    // top:10px;
    transform: translate(75%,-140px);
    text-align: end;
    cursor: pointer;
    display: inline-flex;
}
.p-eva-grupos-container-icon svg
{
    height: 30px;
    width: 30px;
    background-color: #F47647;
    border-radius: 50%;
    padding: 4px;
}

.p-eva-grupos-icon
{
    margin-left: 4px;
}
// .p-eva-buttonGroup
// {

// }

// @media (max-width: 550px) {

//     .p-eva-cardGrupo
//     {
//         width: 100%;
//         max-width: 100% !important;
//     }

//     .p-eva-img
//     {
//         width: 100%;    
//         max-width: 100% !important;
//     }
// }