.p-dialog-header-icon
{
    position: absolute !important;
    transform: translate(20px,-35px);
    background: #FFFFFF !important;
}
.p-dialog-header
{
    border-top-left-radius: 16px !important; 
    border-top-right-radius: 16px !important; 
}

.p-dialog-footer
{
    border-bottom-left-radius: 16px !important; 
    border-bottom-right-radius: 16px !important; 
}

.p-modalEva-header
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.modalEva-header-etiqueta
{
    display: flex;
    justify-content: end;
}
.modalEva-header-titulo
{
    display: flex;
    align-items: center;
}

.modalEva-header-etiqueta-content
{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fef2ed;
    //opacity: 0.1;
    border-radius: 16px;
    // padding-top: 6px;
    // padding-bottom: 6px;
    padding: 6px 16px 5px 16px;
    span
    {   
        //padding: 6px 16px 5px 16px;
        color: #F47647;
    }
}
@media (max-width: 991px) {
    .p-modalEva
    {
        max-height: 125vw !important;
        top: -110px;
    }
}