.p-eva-tabview 
{
    margin-top: 32px;
    .p-tabview-nav-content
    {
        border-radius: 16px;
    }
    .p-tabview-nav 
    {
        
        li.p-highlight .p-tabview-nav-link
        {
            background: #F47647 !important;
            border-color: #F47647;
        }
        li .p-tabview-nav-link
        {
            color:#B5B5B5;
        }
    }
    ul
    {
        li
        {   
            width: 100%;
            
        }
        .p-tabview-selected a
        {
            background-color: #F47647 !important;
            border-radius: 16px !important;
            color: white !important;
            text-align: center !important;
            box-shadow: none;
        }
        .p-tabview-ink-bar{
            background: transparent !important;
        }

    }
    .p-tabview-panels
    {
        background: #F5F5F5;
        padding-left: 0;
        padding-right: 0;
    }
    
}
.p-tabview-title
{   display: flex;
    justify-content: center;
    width: 100%;
    text-align: center!important;
}
.p-unselectable-text a .p-tabvieweva-num
{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px #F47647 solid;
    margin-left: 8px;
    border-radius:100% !important;
    background: #F47647;
    color: #ffffff;
    font-size: 12px;
    padding: 8px !important;
}

.p-tabview-selected.p-highlight a .p-tabvieweva-num
{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px #F47647 solid;

    padding: 8px !important;
        font-size: 12px;
        background: #ffffff;
        color: #F47647;
        border-radius:100%

}