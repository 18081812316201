
.p-eva-dashboard-inicio,
.p-eva-dashboard-inicio-right
{
   height: 87vh !important;
}
.p-eva-dashboard-inicio-right,
.p-eva-dashboard-inicio-publicaciones
{
   overflow-y: auto;
}
.p-eva-dashboard-inicio-mobile
{
   display: none;
}



@media (max-width: 991px) {
   .p-eva-dashboard-inicio-mobile
   {
      display: block !important;
   }

   .p-eva-dashboard-inicio
   {
      display: none !important;
   }
   .p-eva-dashboard-inicio-right
   {
      margin: 0 !important;
   }

  
}