.p-eva-cardCumpleanios-item
{
    display: inline-flex;
    align-items: center;
    border: 1px solid #F5F5F5;
    border-radius: 16px;
    padding: 8px;
    background: #F5F5F5;
}
.item-link span 
{
 a {
    display: flex;
    justify-content: end !important;
 }   
}
.header-cumpleanios-titulo
{
    //padding-left: 16px;
    padding-top: 60px;
    label
    {
        padding-top: 16px;
        font-weight: 700;
        font-size: 18px;
    }
}

.p-eva-cardCumpleanios .p-card-body
{
    padding: 0;
    // position: relative;
    //height: fit-content;
    .p-card-content
    {
        //height: 347px;
        height: fit-content;
        max-height: 347px;
        padding: 24px;
        padding-top: 8px;
        overflow-y: auto;
    }
    // .p-card-footer
    // {
    //     padding: 0;
    // }
}
.p-eva-cardCumpleanios .p-card-footer
{
    display: flex;
}
.p-eva-dialog-message {
    height: 310px; // Increased height value
    width: 700px;
  }