.p-eva-cardEntry-wrap {
    // overflow: hidden;
    position: relative;
    display: flex;
    margin-top: 16px;
  }
.p-eva-cardEntry-fondo{
    
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    //opacity: 0.5;
    border-radius: 16px;
}
.eva-cardEntry .p-card-title
{
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 27px;
    height: 50% !important;
    color: black;
}
.eva-cardEntry
{
    
    border-radius: 16px;
    //background-image: url("../../../public/images/inicio/ondas.png");
    width: 100%;
    
    height: 193px;
    //opacity: 0.6;
}
.eva-cardEntry .p-card-content
{
    margin-top: 8px;
    padding-top: 0px;
}
.eva-cardEntry .p-card-body
{
    height: 100%;
    padding: 16px;
}

.eva-cardEntry .p-card-footer
{
    margin-top:10%;
    padding: 0;
    button
    {
        padding: 10px;
    }
}
@media (max-width: 991px) {
    .eva-cardEntry .p-card-footer
    {
        margin-top :8% !important;
    }
}


.p-eva-cardEntryImg-wrap {
    // overflow: hidden;
    position: relative;
    display: flex;
    margin-top: 16px;
  }
.p-eva-cardEntryImg-fondo{
    
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
    //opacity: 0.5;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
.eva-cardEntryImg .p-card-title
{
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 27px;
    height: 65px !important;
    color: black;
}
.eva-cardEntryImg
{
    
    border-radius: 16px;
    //background-image: url("../../../public/images/inicio/ondas.png");
    width: 100%;
    
    height: 311px;
    //opacity: 0.6;
}
.eva-cardEntryImg .p-card-content
{
    margin-top: 8px;
    padding-top: 0px;
}
.eva-cardEntryImg .p-card-body
{
    margin-top: 150px;
    //height: 100% !important;
    padding: 16px;
}

.eva-cardEntryImg .p-card-footer
{
    margin-top:10px;
    padding: 0;
    button
    {
        padding: 10px;
    }
}