.custom-slider {
    width: 100%;
    margin: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 18px;
    margin-left: 20px;

}

.slider-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 550px; /* Ajusta la altura según sea necesario */
    width:  300px; /* Ajusta la altura según sea necesario */

}

.imagen-item {
    height: 100%; /* Ajusta la altura según sea necesario */
    width:  100%;
}
.pi-slider-next, .pi-slider-prev {
    font-size: 66px !important; /* Increase the size as needed */
  }
.slick-arrow .slick-next{
    font-size: 66px !important;
}
.p-eva-cardPublicacion-img {
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 100%;
    height: 100%;
    margin-top: 10px;
    background-color: rgb(253, 253, 253);
    border-radius: 18px;

    

    img {
      max-width: 100%;
      max-height: 550px; /* Añadido para ocupar toda la altura del contenedor */
      object-fit: contain; /* Añadido para mantener la relación de aspecto */
      display: block;
      margin: 0 auto;

    }
  }

  .p-eva-cardPublicacion-img2 {
    // display: flex;
    // justify-content: center;
     align-items: center; /* Añadido para centrar verticalmente */
    // width: 100%;
    // height: 100%; /* Añadido para ocupar toda la altura del contenedor */
    // margin-top: 10px;
    // margin-bottom: 10px;
    background-color: rgb(255, 255, 255);
    border-radius: 18px;
    

    img {
      max-width: 100%;
      max-height: 100%; /* Añadido para ocupar toda la altura del contenedor */
      object-fit: contain; /* Añadido para mantener la relación de aspecto */
      display: block;
      margin: 0 auto;

    }
  }

  @media screen and (max-width: 1380px) {
    .p-eva-cardPublicacion-img2 {
      img{
        width: 92%;
      }
    }
  }
  @media screen and (min-width: 2400px) {
    .p-eva-cardPublicacion-img2 {
      img{
        width: 94%;
      }
    }
  }
