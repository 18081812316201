.p-perfilOptions{
    // width: 12.5rem;
    padding: 0.25rem 0;
    padding: 0.5rem;
    //background:  #FFFFFF;;
}
.p-perfilOptions-lista{
    margin: 0;
    padding: 0;
    list-style: none;
}

.p-perfilOptions-lista > .p-perfilItem {
    position: relative;
}

.p-perfilOptions .p-perfilItem > a {
    padding: 1.25rem;
    //border: 1px solid #dee2e6;
    color: #000000;
    background: #ffffff;
    border-radius: 6px;
    transition: box-shadow 0.2s;
    margin-bottom: 10px;
    vertical-align: middle;
   
}
.p-perfilOptions .p-perfilOptions-lista > .p-perfilItem > .p-perfilItem-link {
    padding: 0.75rem 1.25rem;
    
    border-radius: 16px;
    transition: box-shadow 0.2s;
    user-select: none;
    vertical-align: middle;
}
.p-perfilOptions .p-perfilItem-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
}

.p-perfilOptions .p-perfilOptions-lista > .p-perfilItem > .p-perfilItem-link .p-perfilItem-icon {
    color: #000000;
    margin-right: 0.5rem;
}

.p-perfilOptions .p-perfilOptions-lista > .p-perfilItem > .p-perfilItem-link > .p-arrowPerfilItem-icon {
    margin-left: auto;
    color: #F47647;
}

.p-perfilOptions .p-perfilOptions-lista > .p-perfilItem > .p-perfilItem-link > .p-perfilItem-text {
    //font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}




.p-perfilItem-icon > svg{
    width: 24px;
    height: 24px;
}

.p-perfilOptions-close .p-sidebar-header
{
    display: none;
}
.p-sidebar-content .p-sidebar-header-back
{
    padding-left:0;
}
.p-sidebar-header-back
{
   
    display: block !important;
} 

 .p-sidebar-header-back-close
{
    display: none !important;
} 


/* TAB VIEW */

.containerTab {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 432px;
    height: 300px;
    //background: #f1f1f1;
    //margin: 30px auto 0;
    word-break: break-all;
    //border: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .bloc-tabs {
    display: flex;
    background-color: #FFFFFF;
    border-radius: 16px;
  }
  .tabs {
  
    padding: 15px;
    text-align: center;
    width: 100%;
    background: rgba(128, 128, 128, 0.075);
    cursor: pointer;
    //border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    //box-sizing: content-box;
    position: relative;
    outline: none;
    background-color: #FFFFFF;
    border-radius: 16px;
    color : #B5B5B5;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    
  }
  
  
//   .tabs:not(:last-child){
//     //border-right: 0px solid rgba(0, 0, 0, 0.274);
//   }
  
  .active-tabs  {
    background: white;
    border-bottom: 0px solid transparent;
    border-radius: 16px;
    background-color: #F47647;
    color: #ffffff;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    text-align: center;
  }
  
  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    //background: rgb(88, 147, 241);
  }
  
  
  
  button {
    border: none;
    
  }
  .content-tabs {
    flex-grow : 1;
  }
  .contentTabs {
    background: #F5F5F5;
    padding-top: 20px;
    width: 100%;
    height: 100%;
    display: none;
  }
  .contentTabs h2 {
    padding: 0px 0 5px 0px;
  }
  .contentTabs hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  }
  .contentTabs p {
    width: 100%;
    height: 100%;
  }
  .active-content {
    display: block;
  }

  .p-perfilOtions-avatar-container
  {
    position: relative;
    height: 180px;
  }
  .p-perfil-avatar
  {
    position: absolute;
  }
  .p-perfil-avatar-img
  {
    display: block;
    height: 160px;
    border-radius: 50%;
  }
  .p-perfil-avatar-upload
  {
    position: absolute;
    text-align: center;
    //z-index: 2;
    background-color: #F47647 ;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    top: 110px;
    left: 110px;
    cursor: pointer;

  }
  .p-perfil-avatar-upload-icon
  {
    height: 50PX;
    width: 50PX;
    svg{
      margin-top: 10px;
    }
  }
  .p-perfil-avatar-name
  {
    position: absolute;
    z-index: 2;
    width: 60%;
    right: 5px;
    top: 20px;
    text-align: start;

    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
  }

  .p-perfil-avatar-puesto
  {
    position: absolute;
    z-index: 2;
    width: 60%;
    right: 5px;
    top: 100px;
    text-align: start;

    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }
  .p-eva-dialogEditImg
{
    width: 500px;
    height: 500px;
    border-radius: 16px !important;
}
