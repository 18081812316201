.p-eva-subirBanner
{
    width: 100%;
    .p-card-header
    {
        padding-top:  16px !important;
        padding-left:  16px !important;
    }
    .p-card-content
    {
        padding-top: 0px;
    }
}
.p-eva-subirBanner-footer
{
    display: flex;
    align-items: center;
    justify-content: end;
    i
    {
        margin-right: 40px;
        font-style: normal;
        font-weight: 600;
        cursor: pointer;
        color: #F47647;
    };


}
.p-eva-subirBanner-footer i:hover
{
    text-decoration: underline;
}
