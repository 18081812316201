.p-compartir-listBox
{
    border: none;
    // .p-listbox-item
    // {
    //     cursor: auto;
    // }
    // .p-listbox-item:hover
    // {
    //     background: transparent !important;
    // }
}


