.contentLogin {
    position: absolute ;
    top: 155px;
    left: 60%;
    //opacity: 0;
    // width: 40% !important;
    // height: 80% !important; 
    width: fit-content!important;
    height: fit-content!important;
    border-radius: 16px !important; 
    padding: 0;
    // padding: 56px !important;
    padding-top: 56px;
    padding-bottom: 56px;
  }
  
  .contentLogin p.error {
    margin: 0 0 10px 10px;
    text-align: left;
    font-size: 10px;
    color: red;
  }
  .peva-button-login
  {
    width: 100%;
    border-radius: 16px;
    background: #463347;
    
  }

  .peva-request-pass
  {
    width: 100%;
    margin-top :14px;
    text-align: center;
    color:#463347;
    text-decoration: underline;
  }


.form-login
{
  margin-left: 72px;
  margin-right: 72px;
  margin-top: 60px;
  input
  {
      color: black !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      padding: 14px;
  }
  
}
.form-img{
  margin-left: 72px;
  margin-right: 72px;
}
.form-footer
{
  display: flex;
  justify-content: center;
  button{
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
  }
}
@media (max-width: 991px) {
  .contentLogin
  {
    top: 190px;
    left: 5%;
    width: 90%!important;
    height: 60%!important;
    padding-top: 32px;
    padding-bottom: 32px;
    img
    {
      width: 196px;
    }
  }
  .form-login
  {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 32px;
  }
}