.header-directorio-area 
{
    
    margin-bottom: 50px;
    label{
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
    }
}