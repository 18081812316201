.reconocimientos-body-repositorio
{
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 45px;
    grid-auto-rows: minmax(100px, auto);
}

// .repositorio-item-header-img
// {
//     display: grid;
//     align-items: center;
//     justify-content: center;
//     div
//     {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         font-style: normal;
//         font-weight: 700;
//         font-size: 18px;
//         line-height: 21px;
        
//         color: #000000;
//         padding-top: 15px;
//     }
// }

// .repositorio-item_reconocimiento {
//     position: relative;
//     display: grid;
//     justify-content: center;
//     padding: 16px;
//     width: 344px;
//     height: 268px;
//     border-radius: 16px;
//     background: #FFFFFF;
// }
@media (max-width: 991px) 
{
    .eva-dashboard-editarCategoria,
    .eva-detalleCategoria,
   .eva-dashboard-editarReconocimiento,
    .eva-reconocimientos
    {
        width: 100% !important;
    }
    .reconocimientos-body-repositorio {
       
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 45px;
        gap: 45px;
        //grid-auto-rows: minmax(100px, auto);
        .repositorio-item
        {
            width: 100%;
            .repositorio-item-delete
            {
                transform: translate(350px, 10px);
            }
        }
    }
}