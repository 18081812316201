.custom-slider {
    width: 100%;
    margin: auto;
   //background-color: blue;

}

.slider-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 696px; /* Ajusta la altura según sea necesario */
    width:  300px; /* Ajusta la altura según sea necesario */

}

.imagen-item {
    height: 100%; /* Ajusta la altura según sea necesario */
    width:  100%;
}
// .p-eva-cardPublicacion-img
// {
//     display: flex;
//     justify-content: center;
//     width: 100%;
//     margin-top: 10px;
//     margin-bottom: 10px;
//     img
//     {
//         max-width: 100%;
//         max-height: 550px;
//     }
// }

.p-eva-cardPublicacion-img {
    display: flex;
    justify-content: center;
    align-items: center; /* Añadido para centrar verticalmente */
    width: 100%;
    height: 100%; /* Añadido para ocupar toda la altura del contenedor */
    margin-top: 10px;
    img {
      max-width: 100%;
      max-height: 100%; /* Añadido para ocupar toda la altura del contenedor */
      object-fit: contain; /* Añadido para mantener la relación de aspecto */
      display: block;
      margin: 0 auto;
    }
  }
