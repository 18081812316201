.p-eva-container-icon svg
{
    height: 30px;
    width: 30px;
    background-color: #F47647;
    border-radius: 50%;
    padding: 4px;
}

// .p-eva-icon
// {
//     padding-top: 10px;
// }

.p-eva-icon {
    margin-top: 30px; /* Puedes ajustar este valor según tu necesidad */
  }
