.p-eva-publicacionGrupo
{
    overflow-y: auto;
    overflow-x: hidden;
}
// .p-eva-dashboard-body
// {
//     height: 71vh !important;
// }
.p-eva-dashboard-body-tabMobile
{
    display: none;
}



.p-eva-publicacion-aprobacion
{
    background: #FFFFFF;
    border-radius: 16px;
    padding: 16px;
    display: inline-flex;
    align-items: center;
}
.publicacion-aprobacion-info
{
    width: 60%;
    border-right: 1px solid #f5f5f5;
}
.publicacion-aprobacion-buttons
{
    width: 40%;
    display: flex;
    justify-content: center;
}
.publicacion-aprobacion-info-header
{
    display: flex;
    align-items: center;
    padding-bottom:16px ;
    
}
.aprobacion-info-header-avatar
{
    padding: 8px;
    span
    {
        
        display: block;
    }
    
}
.p-eva-aprobacion-img
{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top:8px;
    img
    {
        max-height: 240px;
        border-radius: 16px;
    }
}

@media (max-width: 991px) {
    .p-eva-dashboard-body-tabMobile
    {
        display: block;
        
    }
    .p-eva-dashboard-body
    {
        display: none !important;
    }
    .p-eva-dashboard-body-tab
    {
        display: none !important;
    }
    .p-tabview-panels
    {
        padding-left: 0 !important;
        padding-right: 0 !important;
        background: transparent !important;
    }
    .p-eva-crearPublicacion
    {
        border-radius: 0px !important;
    }
    .p-card .p-component .p-eva-cardPublicacion
    {
        border-radius: 0px !important;
    }
}