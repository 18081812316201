.p-eva-inputPublicacion-container
{
    border-radius: 16px !important;
    background: #F5F5F5 !important;

    justify-content: flex-start;
    align-items: flex-start;
    flex: 1 1 auto;
    // max-width: calc(100% - 50px)
    //border: 1px solid #000000;
    //padding-bottom: 10px;
    //position: relative;
    // span
    // {
    //     //position: absolute;
    //     transform: translate(-30px,18px);
    //     cursor: pointer;
    // }
    
}
.p-eva-inputPublicacion-container:hover
{
    border: 1px solid #000000;
}


.flex-wrap
    {
        flex-wrap: wrap !important;
    }
    .display-flex
    {
        display: flex!important;
        align-items: center;
    }
.comments-comment-box-comment__text-editor
{
    flex: 1 1 auto;
    min-width: 0;
}
.mlA
{
    margin-left: auto !important;
}
                  
.p-editor-container .p-editor-content .ql-editor
{
    background: transparent;
    color: #000000 !important;
    box-shadow: none !important
    
}

.ql-container.ql-snow
{
    border: none !important;
    color: #000000 !important;
    
}

.ql-editor .ql-blank::placeholder
{
    color: #000000 !important;
}
.cursor-pointer
{
    cursor: pointer;
}
.cursor-not-alowed
{
    cursor: not-allowed;
}