.eva-dashboard-footer
{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top:24px ;
    span
    {
        display: flex;
    }
}
.footer-fechaActualizado
{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #B5B5B5;
}
.p-eva-organigrama-contactCard
{
    display: flex;
    
}
.p-eva-organigrama-contactCard.p-card-body
{
    .p-card-content
    {
        display: flex;
    }
    
}
.organigrama-item-contactCard
{
    margin-right: 12px;
    margin-left: 12px;
    margin-bottom: 12px;
}

@media (max-width: 991px) 
{
    .p-eva-organigrama-contactCard
    {
        .p-eva-DetalleTema-cardContactIcon
        {
            width: auto ;
        }
    }
}