.reglamento-body-tituloSeccion{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 16px;
}
@media (max-width: 991px) 
{
    .eva-dashboard-reglamento,
    .eva-dashboard-reglamentoGerencia
    {
        width: auto !important;
    }
    .eva-card-gerencia
    {
        width: 100%;
    }
    .CardReglamento
    {
        height: fit-content!important;
    }
}