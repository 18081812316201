.p-eva-dropdownDefault
{
    border-radius: 16px;
    border: none;
    box-shadow: none;
}
.white
{
    background: #FFFFFF;
}
.grey
{
    background: #F5F5F5;
}
.p-eva-dropdownDefault:hover
{
    border: 1px solid #000000 !important;
}
.p-eva-dropdown-error:hover
{
    border: 1px solid #DC0922 !important;
}
.p-eva-dropdown-error
{
    border: 1px solid #DC0922 !important;
}
.p-eva-dropdown-success:hover
{
    border: 1px solid #00B560 !important;
}
.p-eva-dropdown-success
{
    border: 1px solid #00B560  !important;
}