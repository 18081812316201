
.eva-dashboard-header
{
    color: #000000;
}
.grupo-header
{
    margin: 32px !important;
    margin-left: 0 !important;
    font-style: normal;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-content: space-between ;
}

.header-crear
{
    
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    text-align: right;
    
}
.header-crear a
{
    color: #F47647 !important;
    margin-right: 20px;
}
.p-eva-dialogDelete
{
    width: 500px;
    //height: 300px;
    height: fit-content;
}
.p-eva-dialogDelete .p-dialog-header
{
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    padding: 9px !important;
}

.p-eva-dialogDelete .p-dialog-footer
{
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    display: inline-flex;
}
.p-eva-dialogDelete .p-dialog-content 
{
    text-align: center;
    label{
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
    }
    

}

@media (max-width: 991px) {
    .eva-dashboard-grupos
    {
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 100px !important;
    }
    .p-eva-cardGrupo
    {
        width: 100% !important;
        max-width: 100%;
    }
    .p-eva-Container-img
    {
        img
        {
            width: 100% !important;
            max-width: 100%;
        }
    }
    .eva-dashboard-crearGrupo
    {
        padding: 20px !important;
    }
}