.onboarding-item
{
    // display: flex;
    border-radius: 16px;
    background: #ffffff;
    margin-top: 8px;
    padding: 15px;
    height: 50px;
    width: 100%;
    
}

.onboarding-seccion
{
    display: flex;
    align-items: center;
    padding: 19px 24px 19px 24px;
    gap: 12px;
    width: 1082px;
    height: 142px;
  
    background: #FFFFFF;
    border-radius: 16px;
    .seccion-detalle-item
    {
        width: 918px;
        max-height: 70px;
        
        background: #F5F5F5;
        border-radius: 16px;
        padding: 15px 16px 14px 16px;
        span
        {
            width: 813px;
            height: 21px;
           
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            
            color: #000000;
        }
    }
}
.onboarding-seccion-opacity{
    opacity: 0.2;
    pointer-events: none;
}
.onboarding-valores
{
    display: grid;
    // gap: 16px;
    padding: 24px;
    background: #ffffff;
    width: 100%;
    //height: 361px;
    max-height: 816px;
    border-radius: 16px;
}
.onboarding-valores-body
{
    display: flex;
    gap: 16px;
}
.valores-item
{
    display: grid;
    justify-content: center;
    padding: 16px;
    box-sizing: border-box;

    width: 159px;
    height: 260px;
   
    /* Basic/Blanco */

    background: #FFFFFF;
    /* Basic/Gris oscuro */

    border: 1px solid #B5B5B5;
    border-radius: 16px;
}
.valores-item-descripcion
{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */

    text-align: center;

    /* Basic/Negro */
    margin-top: 16px;
    color: #000000;
    height: 36px;
}
.valores-item-img
{
    display: flex;
    justify-content: center;
   
   
    
}
.valores-item-boton
{
    margin-top: 8px;
    
}
.onboarding-valores-footer
{
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 32px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #F47647;
}
.onboarding-valores-media
{
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 423px;
    //background: #b5b5b5;
    border-radius: 16px;
    iframe
    {
        border-radius: 16px;
    }
}
.p-eva-dialog-descripcion
{
    width: 540px;
    max-height: 225px;
    .p-dialog-header
    {
        padding: 24px 24px 0px 24px;
    }
    .p-dialog-footer
    {
        padding: 0px 24px 24px 24px;
    }
    .p-dialog-content
    {
        padding: 0px 24px 0px 24px;
    }
}
@media (max-width: 991px) 
{
   

    .eva-onboarding
    {
        width: 100% !important;
    }
    .onboarding-valores
    {
        height: fit-content !important;
        max-height: fit-content;
        //width: 95vw !important;
        .onboarding-valores-body
        {
             width: 100%;
             display: grid !important;
             grid-template-columns: repeat(2, 43vw) !important;
             grid-auto-rows: minmax(50px, auto) !important;
             gap: 2px;
        }
        .onboarding-valores-footer
        {
            height: auto !important;
            width: fit-content !important
        }
        .onboarding-valores-media
        {
            div
            {
                height: fit-content !important;
                width: fit-content !important;
            }
            height: auto !important;
            width: fit-content !important;
            iframe
            {
                width: 85vw !important;
                height: auto !important;
            }
        }
    }
   .onboarding-seccion
   {
        width: 100% !important;
        height: auto !important;
        display: grid !important;
        align-items: center !important;
        justify-content: center !important;
        .onboarding-seccion-img >img{
            justify-content: center !important;
        }
       
            .seccion-detalle-item
            {
                max-height: fit-content  !important;
                width: 85vw!important;
                
                span 
                {
                    width: 85vw !important;
                    height:fit-content   !important;
                    word-wrap: break-word;
                }
            }
            
        
   }
    
}