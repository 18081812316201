.p-eva-cardPublicacion-header
{
    padding: 16px;
}
.p-eva-cardPublicacion
{
    //position: relative;
    margin-bottom: 16px;
    .p-card-body,.p-card-content
    {
        padding-top: 0px !important;
       .p-eva-cardPublicacion-contenido
       {
            margin-bottom: 16px;
       }
    }
    .p-card-footer{
        border-top: 2px solid #F5F5F5;
        padding-top: 8px;
    }
    .p-card-body
    {
        padding-bottom: 8px;
    }
    
}
.cardPublicacion-avatar
{
    display: flex;
    align-items: center;
    padding-right: 8px;
    //width: 100%;
    
}
.cardPublicacion-infoAvatar 
    {
        display: grid;
        padding-top: 0px;
        padding-bottom: 2px;
        //padding-left: 8px;
        width: 560px !important;
        span
        {
            display: block
        }
        
    }
.infoAvatar-grupo
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.p-eva-cardPublicacion-header
{
    display: flex;
    align-items: center;
    //width: 100%;
}
.cardPublicacion-fijadoEdit
{
    //width: 50%;
    display: flex;
    align-items: center;
    //justify-content: end;
    //order: 2;
    gap: 18px;
    .cardPublicacion-fijado
    {
        // background: #fef2ed;
        // padding-top: 6px;
        // padding-bottom: 6px;
        // border-radius: 16px;
        // margin-right: 19px;
        // span
        // {   
        //     padding: 16px;
        //     color: #F47647;
        // }
        display: flex;
        padding: 6px 12px 5px 16px;
        width: 157px;
        height: 32px;
       
        background: #FEF2ED;
        //opacity: 0.1;
        border-radius: 16px;
        
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height */


            /* Branding/Naranja */

            color: #F47647;
    }
}
.p-eva-cardPublicacion-footer
{
    display: flex;
    width: 100% !important;
}
.cardPublicacion-interacion, .cardPublicacion-comentar,.cardPublicacion-compartir
{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0 !important;
    padding: 10px;
    width: 33.3%;
    vertical-align: middle !important;
    span
    {
        //padding-bottom: 5px;
        margin-left: 9px;
        color: black;
    }
    svg
    {
        
        color: black;
    }
    
    
}
.cardPublicacion-interacion:hover,.cardPublicacion-comentar:hover,.cardPublicacion-compartir:hover
{
    background-color: #F5F5F5 !important;
    box-shadow: 9px solid black !important;
    border-radius: 8px;
    cursor: pointer;
}

.p-eva-dashboard-publicacion
{
    height: fit-content;
}

// .cardPublicacion-interacion
// {
//     position: relative;
// }
.p-eva-cardPublicacion-footer
{
    position: relative;
    
}
.cardPublicacion-comentarios
{
    display: block;
}

.cardPublicacion-comentarios-agregar
{
    display: flex;
    align-items: center;
}

.comentarios-agregar-input span
{
    width: 100%;
    input
    {
        width: 100%;
    }
}
.p-eva-cardPublicacion-img
{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    img
    {
        max-width: 100%;
        //max-height: 350px;
    }
}
.p-eva-cardPublicacion-img-collage{
    width: 100%;
}

// @media (max-width: 991px) {
//     .cardPublicacion-fijadoEdit
//     {
//         order: 1;
//         width: 100%;
//     }
//     .p-eva-cardPublicacion-header
//     {
//         display: block;
//     }
//     .cardPublicacion-avatar
//     {
//         order: 2;
//         justify-content: end;
//     }
// }

.p-eva-interacion-vistas
{
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .p-eva-cardPublicacion-vistas
    {
        margin-top: 16px;
        display: flex;
        align-items: center;
        gap:8px;
    }
}
.mencion-personas {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
    font-size: 12px;
}

@media (max-width: 991px) 
{
    .infoAvatar-grupo
    {
        display: grid !important;
        gap:8px;
        .cardPublicacion-fijadoEdit
        {
            //flex: 1;
            order: 1 !important;
            justify-content: end;
            
        }
        .cardPublicacion-grupo-info
        {
            order: 2 !important;
        }
    }
.cardPublicacion-avatar
{
    display: flex !important;
    align-items: end !important;
}
.p-eva-cardPublicacion-header
{
    align-items: end;
}

}