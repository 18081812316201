.eva-dropdownFiltro
{
    border-radius: 16px;
    border: 1px solid #F47647 !important;
    box-shadow: none    !important;
    .p-dropdown-label
    {
        color: #F47647;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        padding-left: 14px;

    }   
    
}
.p-dropdown .p-dropdown-label.p-placeholder {
    color: #F47647;
}
.p-dropdown-item
{
    color: #000000 !important;
}
.eva-dropdownFiltro:hover
{
    border: 1px solid #000000 !important;

}
.p-dropdown-trigger-icon {
    color: #F47647 !important;
}