.p-eva-documento-pdf
{
    .cardfileHeader-descargar
    {
        transform: translate(890px,-30px) !important;
        //background: # ;
    }
}
// .p-checkbox .p-checkbox-box .p-checkbox-icon {
//     color: black;
// }
// .p-eva-checkbox2{
// background: rgb(4, 4, 4);
// }
// .p-eva-checkbox2 .p-checkbox-box
// {
//     border: 2px solid #ffffff !important;
// }

.p-eva-checkbox .p-checkbox-box
{
    border: 2px solid #F47647;
}

.p-checkbox .p-checkbox-box.p-highlight
{
    border-color: #F47647 !important;
    background: #ffffff !important;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon{
    color: #F47647;
}

.custom-checkbox {
    background-color: #4caf50 !important;; /* Change this to your desired background color */
  }
  
  .custom-checkbox .p-checkbox-box {
    border-color: #4caf50 !important;; /* Change this to your desired border color */
  }
  
  .custom-checkbox .p-checkbox-icon {
    fill: #d01c1c !important;; /* Change this to your desired check color */
  }

@media (max-width: 991px) 
{
    .p-eva-documento-pdf
    {
        width:  90vw !important;
        .p-dialog-content
        {
            width: 100% !important;
            .react-pdf__Page
            {
                width: 100% !important;
                .react-pdf__Page__canvas
                {
                    width:  95vw !important;
                    height: auto !important;
                }
                .react-pdf__Page__annotations.annotationLayer
                {
                    width:  95vw !important;
                }
                .react-pdf__Page__textContent.textLayer
                {
                    width:  95vw !important;
                }
            }
        }
    }
}