
.CardReglamento{
    height: 273px !important;
}

.p-card-title 
{
    display: flex !important;
}
.p-card-title {
    display: flex;
    align-items: center;
    gap:28px;
    svg
    {
        display: flex;
        align-items: center; 
    }

}
.CardReglamento-titulo
{
    display: flex;
    align-items: center;
   
}
.CardReglamento-nombre
{
    padding-top: 2px;
    padding-left: 8px;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
}
.CardReglamento-edit
{
    display: flex;
    align-items: center;
    gap:5px

}

.CardReglamento-titulo
{
    width: 98% !important;    
}

.CardReglamento-edit
{
    cursor: pointer;
}

.CardReglamento
{
    width: 100%;
    margin-right: 16px;
    margin-bottom: 16px;
    // height: 280px;
    height: 100%;
    .p-card-content .reglamento-descripcion
    {
         height: 63px !important;
        height: 100%;
        overflow-y: auto;
    }
}
.p-eva-footer-politica div 
{
    padding-left: 50px;
}