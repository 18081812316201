.p-eva-sidebar-usuario
{
    width: 507px !important;
    top:72px;
    height: 100%;
    background: #F5F5F5;
    // overflow-y: auto;
    // padding-bottom: 20px;
    .p-sidebar-content
    {
        padding: 0px 24px 80px 24px;
        
    }
}
.sidebar-usuario-header
{
    display: flex;
    align-items: center;
    gap: 20px;
    .usuario-header-info{
        display: grid;
        gap: 16px;
        .header-info-nombre
        {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #000000;
        }
        .header-info-puesto
        {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;

            color: #000000;
        }
    }
    
}
.sidebar-usuario-acercademi
{
    margin-top: 24px;
    width: 100%;
    min-height: 137px;

    background: #FFFFFF;
    border-radius: 16px;
    padding: 16px;
}

.sidebar-usuario-cumpleanios,
.sidebar-usuario-telefono,
.sidebar-usuario-correo
{
    display: flex;
    align-items: center;
    gap: 8px;

    margin-top: 16px;
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 14px;
}
