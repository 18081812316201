.eva-card-gerencia .p-card-title
{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 0px;
    height: 40px;
}
.eva-card-gerencia
{
    border-radius: 16px;
    background-image: url("../../../public/images/inicio/ondas.png");
    width: 257px;
    height: 193px;
}
.eva-card-gerencia .p-card-content
{
    margin-top: 8px;
    padding-top: 0px;
}
.eva-card-gerencia .p-card-body
{
    height: 100%;
    padding: 16px;
    color: #000000 ;
}

.eva-card-gerencia .p-card-footer
{
    margin-top: 25px;
}
@media (max-width: 991px) 
{
    .eva-card-gerencia
    {
        background-size: 100% !important;
    }
}