.p-eva-subirAniversario
{
    width: 372px;
    height: 300px;

    background: #FFFFFF;
    border-radius: 16px;
    padding: 16px;
    .subirAniversario-header
    {
        padding-bottom: 24px;
        font-weight: 700;
        font-size: 17px;
        line-height: 27px;
        color: #000000;
    }
}