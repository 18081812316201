.capacitacionIntro-secciones
{
    margin-top: 24px;
}

.capacitacionIntro-secciones-item
{
    background: #FFFFFF;
    border-radius: 16px;
    width: 529px;
    height: 176px;      
    //margin: 24px;
}
.grid-secciones
{
    gap:24px
}

.capacitacionIntro-secciones-item
{
    display: flex;
    align-items: center;
    padding: 15px;
    width: 529px;
}
.secciones-item-desc
{
    display: block;
    align-items: center;
    justify-content: center;
    width: 329px;
    margin: 15px;
}
.secciones-item-img
{
    width: 128px;
    margin: 15px;
}
.item-desc-titulo
{
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: black;
}
.item-desc-detalle
{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: black;
}
@media (max-width: 991px) {
    // .eva-capacitacionIntro
    // {
    //     padding-left: 16px;
    //     padding-right: 16px;
    // }
    .eva-dashboard-editarCapacitacionIntro,
    .eva-capacitacionIntro
    {
        width: 100% !important;
    }
    .capacitacionIntro-secciones-item {
        
        padding: 15px;
        width: 100%
    }
}
