.p-eva-calendarDefault input
{
    border-radius: 16px !important;
}
.whiteCalendar
{
    input{
        background: #FFFFFF !important;
        border: none;
    }
}
.greyCalendar
{
    input
    {
        background: #F5F5F5 !important;
        border: none;
    }
    
}
.p-eva-calendarDefault input:hover
{
    border: 1px solid #000000 !important;
}
.p-eva-calendarDefault-error input:hover
{
    border: 1px solid #DC0922 !important;
}
.p-eva-calendarDefault-error input
{
    border: 1px solid #DC0922 !important;
}
.p-eva-calendarDefault-success input:hover
{
    border: 1px solid #00B560 !important;
}
.p-eva-calendarDefault-success input
{
    border: 1px solid #00B560  !important;
}