.p-eva-documentoAniversario
{
    width: 372px;
    height: 262px;

    background: #FFFFFF;
    border-radius: 16px;
    padding: 16px;
    .documentoAniversario-header
    {
        padding-bottom: 24px;
        font-weight: 700;
        font-size: 17px;
        line-height: 27px;
        color: #000000;
    }
}
.documentoAniversario-body
{
    display: grid;
    align-items: center;
    gap: 8px;
    .documentoAniversario-body-item
    {
        display: flex;
        align-items: center;
        padding: 16px;
        justify-content: space-between;

        width: 340px;
        height: 72px;
    
        background: #F5F5F5;
        border-radius: 16px;
    }
}
