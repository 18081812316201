.colaborador-item
{
    display: inline-flex;
    align-items: center;
}
.p-eva-listaColaboradores
{
    .p-listbox
    {
        min-width: 100%;
        border: none;
    }
    
}
.p-eva-cardColaboradores .p-card-body
{
    padding-top: 0px !important;
}
.p-eva-colabpradores-footer
{

    display: flex;
    align-items: center;
    justify-content: center;
}