.eva-dashboard-header.metricas
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.grid-metrica-publicaciones
{
    gap :16px;
    margin-right: 0;
    margin-left: 0;
}
.card-metrica
{
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 16px;
    height: 116px;
    gap: 16px;
}

.metricas-colaboradores
{
    display: flex;
    align-items: center;
    width: 716px;
    padding: 16px 24px 16px 24px ;
    
}
.metricas-vistas
{
    width: 350px;
    padding: 16px 24px 16px 24px ;
  
    
}
.metricas-interacciones,
.metricas-publicaciones,
.metricas-comentarios
{
    height:151px;
    width: 350px;
    padding: 34px 24px 34px 24px ;
}

.metricas-colaboradores .metricas-icon
{
    background: rgba(244, 118, 71, 0.1);
}
.metricas-vistas .metricas-icon
{
    background: rgba(70, 51, 71, 0.1);
}
.metricas-publicaciones .metricas-icon
{
    background: #D3FFE7;
}
.metricas-interacciones .metricas-icon
{
    background: #CAF1FF;
}
.metricas-comentarios .metricas-icon
{
    background: #FACEE3;
}

.metricas-icon
{
    display: flex;
    align-items: center;
    justify-content: center;
   
    width: 84px;
    height: 84px;
    border-radius: 100%;
}
.metricas-textoL
{
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    //width: 378px;
    //padding-left: 16px;
}
.metricas-textoS
{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}
.metricas-textoSl
{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;

}
.metricas-comentarios-porcentaje,
.metricas-interacciones-porcentaje,
.metricas-publicaciones-porcentaje,
.metricas-vistas-porcentaje,
.metricas-colaboradores-porcentaje
{
    //padding-left: 16px;
    display: flex;
    align-items: center;
}

.metrica-secciones
{
    display: grid;
    align-items: center;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 24px;
    margin-top: 24px;
    gap:24px
}
.metrica-usuarios
{
    display: grid;
    align-items: center;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 24px;
    margin-top: 24px;
    .metrica-usuarios-header
    {
        display: flex;
        align-items: center;
        
    }
    .usuarios-lista
    {
        display: grid;
        justify-content: end;
        gap: 16px;
        margin-top: 40px;
        .usuarios-item-header
        {
            display: flex;
            align-items: center;
        }
        .usuarios-item
        {
            display: flex;
            align-items: center;
            width: 1010px;
            height: 80px;
            background: #F5F5F5;
            border-radius: 16px;
            .usuarios-item-nombre
            {
                width: 280px;
            }
            .usuarios-item-marcar
            {
                width: 168px;
            }
            .usuarios-item-gerencia
            {
                width: 248px;
            }
            .usuarios-item-puesto
            {
                width: 200px;
            }
        }
    }
}
.header-metricas
{
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;

    color: #000000;
}

.seccion-item
{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .p-progressbar-value.p-progressbar-value-animate
    {
        
        background: linear-gradient(90deg, #6FE6A5 0%, #25CF72 100%); 
        border-radius: 8px;
        justify-content: end;
       
    }
 
}
.secciones-lista
{
    display: grid;
   
}
.seccion-porcentaje
{   
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;

    color: #000000;
}
.p-eva-panel-acordeon.metricas
{
    background: #F5F5F5 !important;
    border-radius: 8px; 
    margin-top: 16px !important;
    .p-panel-header
    {
        color: #000000;
        background: #F5F5F5 !important;
        padding-bottom: 0px !important;
    }
    .p-panel-content
    {
        padding-top: 0px !important;
        background: #F5F5F5 !important;
    }
}