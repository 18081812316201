.p-eva-BoxReacciones
{
    img
    {
        padding-left: 8px;
        padding-right: 8px;
    }
   
    position: absolute;
    transform: translateY(-80px);
    display: inline-flex;
    align-items: center;
    height: 80px;
    width: fit-content;
    background : #FFFFFF;
    // background: grey;
    border-radius: 16PX;
    padding: 8PX;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}
.p-eva-mostrar
{
    display: inline-flex;
}
.p-eva-oculto
{
    display: none;
}

//  .p-eva-BoxReacciones-megusta:hover,
//  .p-eva-BoxReacciones-meencanta:hover,
//  .p-eva-BoxReacciones-meimporta:hover,
// .p-eva-BoxReacciones-medivierte:hover,
//  .p-eva-BoxReacciones-measombra:hover,
//  .p-eva-BoxReacciones-meentristece:hover,
//  .p-eva-BoxReacciones-meenoja:hover
.p-eva-BoxReacciones-item:hover
{
    // background: transparent !important;
    height: 48px;
    transition: 0.3s;
    cursor: pointer;
    padding-left: 4px;
    padding-right: 4px;
}
.p-eva_boxReacciones-tooltip
{
    // top: 180px !important;
    transform: translateY(-12px);
    .p-tooltip-text
    {
        background: black;
        font-weight: 700;
    }
    .p-tooltip-arrow
    {
        display: none;
    }
    
}