@media (max-width: 991px) 
{
    .eva-dashboard-editarPolitica,
    .p-eva-politica,
    .eva-detalleActividad,
    .eva-dashboard-diversidad
    {
        width: 100% !important;
    }
}

@media (max-width: 991px) {
    .eva-dashboard-desarrollo
    {
        width: 100% !important;
    }
    .p-eva-timeLine
    {
        overflow-x: auto;
    }
    .timeline-seccion-link {
        // margin-top: 24px;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // padding: 24px;
        //width: 1082px;
        //height: 96px;
        display: grid !important;
        align-items:center;
        justify-content: center !important;
        width: 100%;
        height: fit-content;
        background: #FFFFFF;
        border-radius: 16px;
    }
}