.p-eva-slideNotificaciones
{
    top: 72px;
    width: 500px !important;

    background: #f5f5f5;
}
.slideNotificaciones-item-info
{
    background: #FFFFFF;
    cursor: pointer;
}

.notificacion-pendiente
{
    border: 1px solid #F47647;
    background: rgba(244, 118, 71, 0.1);
}