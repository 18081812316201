.eva-dashboard-Historias-body
{
    display: flex;
    .p-card-body
    {
        padding-top: 0px;
        .p-card-content
        {
            padding-top: 0px;
        }
    }
}

.p-eva-card-historia
{
    min-width: 344px;
    max-width: 344px;
}

.card-historia-content
{
    padding-top: 16px;
    .historia-content-titulo
    {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        color: black;
    }
}
.card-historia-content-descripcion
{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: black;
}