.p-eva-info-header
{
    display: flex;
    align-items: center;
    padding-bottom: 8px;
}

.p-eva-info-header-avatar
{
    padding: 8px;
    span
    {
        
        display: block;
    }
}
.p-eva-publicacionCompartida
{
    margin-top: 16px;
    border-radius: 16px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.publicacionCompartida-img
{
    display: flex;
    justify-content: center;
    width: 100%;
    img
    {
        max-height: 260px;
        max-width: 100%;
        border-top-left-radius: 16px;
        border-top-right-radius: 15px;
    }
}
.publicacionCompartida-body
{
    padding: 16px;
}