.p-eva-cardFile
{
    // height: 290px;
    width: 100%;
    cursor: pointer;
    z-index: -1;
}
.p-eva-cardFile .p-card-body
{
    height: 20% !important;
    border-top: 1px solid lightgray;
    padding-top: 10px;
    .p-card-content
    {
        padding: 0;
    }
}

.p-eva-cardFile .p-card-header
{
    height: 80%;
    display: flex;
    .pg-viewer-wrapper
    {
        overflow: hidden !important;
        .pg-viewer{
            width: 100%;
            .photo-viewer-container
            {
                width: 100% !important;
                img
                {
                    width: 100% !important;
                    height: 100%!important;
                    border-top-left-radius: 16px;
                    border-top-right-radius: 16px;
                }
            }
            .pdf-viewer-container 
            {
                width: 100% !important;
                border-radius: 16px;
                padding: 20px;
                .pdf-viewer
                {
                    
                    .pdf-canvas canvas
                        {
                            align-content: center !important;
                            width: 100% !important;
                        }
                }
            }
        }
    }
}

.p-eva-cardFile-documentIcon
{
    display:flex;
    align-items: center;
    width: 100%;
    span 
    {
        //justify-content: start;

        padding-top: 0px;
        padding-bottom: 2px;
        padding-left: 6px;
    }
}

.p-eva-videoplayer
{
    display: flex;
    .p-dialog-content
    {
        height: fit-content;
    }
    
    
}
// .nombre-archivo
// {
//     white-space: pre-wrap;      /* Webkit */    
//    white-space: -moz-pre-wrap; /* Firefox */     
//    white-space: -pre-wrap;     /* Opera <7 */    
//    white-space: -o-pre-wrap;   /* Opera 7 */     
//    word-wrap: break-word;      /* IE */ 
// }
.nombre-archivo
{
    max-width: 256px;
    overflow: hidden;
}
.p-eva-cardfileHeader
{
    display: flex;
    justify-content: center;
    justify-content: center;
    height: 234px;
    width: 250px !important; 
    
}
.cardfileHeader-pdf
{
    width: 250px;
    height: 234px;
    
}

.cardfileHeader-descargar
{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin: 8px;
    background: #F47647;
    backdrop-filter: blur(5px);
    /* Note: backdrop-filter has minimal browser support */
    
    border-radius: 8px;
    z-index: 1;
    height: 48px;
    width: 48px;
    cursor: pointer;

    transform: translateX(92px);
}

.p-eva-cardArchivo
{
    display: grid;
    align-items: center;
    justify-content: center;
    width: 252px;
    height: 290px;
    background-color: #ffffff;
    border-radius: 16px;
    z-index: -1;
    cursor: pointer;
    .cardArchivo-img
    {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 234px;
        max-width: 252px;
        img
        {
            max-height: 224px;
            // border-top-left-radius: 16px;
            // border-top-right-radius: 16px;
        }
       
    }
    
    .cardArchivo-detalle
    {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 8px 12px 8px;
        max-width: 252px;
        height: 56px;
        word-break: break-all;
    }
}
.p-eva-cardArchivo .cardArchivo-img
{
    display: flex;
    .pg-viewer-wrapper
    {
        overflow: hidden !important;
        .pg-viewer{
            width: 100%;
            .photo-viewer-container
            {
                width: 100% !important;
                img
                {
                    width: 100% !important;
                    height: 100%!important;
                    border-top-left-radius: 16px;
                    border-top-right-radius: 16px;
                }
            }
            .pdf-viewer-container 
            {
                width: 100% !important;
                border-radius: 16px;
                padding: 20px;
                .pdf-viewer
                {
                    
                    .pdf-canvas canvas
                        {
                            align-content: center !important;
                            width: 100% !important;
                        }
                }
            }
        }
    }
}

.imagenEnlace{
    width: 50%;
    height: 50%;
    margin-top: 25%;
}