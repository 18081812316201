.p-eva-card-convocatoria .p-card-body
{
    padding-top: 0 !important;
    
    .p-card-content
    {
        padding-top: 0 !important;
        height: 60px;
    }
}
.p-eva-card-convocatoria.disabled
{
    opacity: 0.3;
    pointer-events: none;
}