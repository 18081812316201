.eva-dashboard-body label{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.eva-dashboard-body
{
    margin-top: 32px;
}
.p-eva-label-busquedaAdavanzada
{
   
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 27px !important;
}

// .p-eva-checkbox .p-checkbox-box
// {
//     border: 2px solid #F47647;
// }

// .p-checkbox .p-checkbox-box.p-highlight
// {
//     border-color: #F47647 !important;
//     background: #F47647 !important;
// }

.p-eva_avatar
{
    justify-content: flex-start;
    width: 100%;
    
}

.p-eva_avatar img
{
    height: 40px;
    width :40px;
    margin-right: 8px;
}
.p-eva-checbox-container
{
    //width: 301px;
    margin-right: 89px;
}

::-webkit-file-upload-button {
    display: none;
 }
 @media (max-width: 991px) {
   
    .eva-dashboard-crearGrupo
    {
        padding: 20px !important;
    }
}